<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script>
import { useAutoCompleteStore } from '../../libs/shared/store/autoComplete.js';

export default {
  data() {
    return {
      autoCompleteStore: useAutoCompleteStore(),
    };
  },

  async mounted() {
    this.$primevue.config.ripple = true;
    if (useCookie('token').value) {
      await this.$store.dispatch('countries/getCountriesRegistration');
      await nextTick();
      await this.autoCompleteStore.getAutoComplete();
    }
  },
};
</script>

<script setup></script>
