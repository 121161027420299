import { default as application_45transferSA5VH0O6BNMeta } from "/root/project/frontend/apps/b2b/pages/application-transfer.vue?macro=true";
import { default as indexKcrOkc850CMeta } from "/root/project/frontend/apps/b2b/pages/applications/index.vue?macro=true";
import { default as indexp2gRn7szicMeta } from "/root/project/frontend/apps/b2b/pages/apply/index.vue?macro=true";
import { default as institute5YZW99K75pMeta } from "/root/project/frontend/apps/b2b/pages/apply/institute.vue?macro=true";
import { default as reviewuTijIf20KJMeta } from "/root/project/frontend/apps/b2b/pages/apply/review.vue?macro=true";
import { default as forgot_45passwordjodcNcqP8pMeta } from "/root/project/frontend/apps/b2b/pages/auth/forgot-password.vue?macro=true";
import { default as loginDM1AA7Ce2cMeta } from "/root/project/frontend/apps/b2b/pages/auth/login.vue?macro=true";
import { default as new_45passwordnVan3PexgxMeta } from "/root/project/frontend/apps/b2b/pages/auth/new-password.vue?macro=true";
import { default as indexXnU9BUvnIjMeta } from "/root/project/frontend/apps/b2b/pages/book-appointment/index.vue?macro=true";
import { default as indexYobFqT3qK8Meta } from "/root/project/frontend/apps/b2b/pages/commission/index.vue?macro=true";
import { default as indexNbMne9546UMeta } from "/root/project/frontend/apps/b2b/pages/course/[id]/index.vue?macro=true";
import { default as indexdGfgqgVBQ8Meta } from "/root/project/frontend/apps/b2b/pages/destinations/index.vue?macro=true";
import { default as indexkns9REpp5gMeta } from "/root/project/frontend/apps/b2b/pages/index.vue?macro=true";
import { default as _91id_93R1P9V8MWvmMeta } from "/root/project/frontend/apps/b2b/pages/institute/[id].vue?macro=true";
import { default as _91id_93nz5njZngdGMeta } from "/root/project/frontend/apps/b2b/pages/institutes/[id].vue?macro=true";
import { default as _91id_93eEn1OiYEoNMeta } from "/root/project/frontend/apps/b2b/pages/institutes/course/[id].vue?macro=true";
import { default as _91id_93QMrmgIxnyeMeta } from "/root/project/frontend/apps/b2b/pages/leads/[id].vue?macro=true";
import { default as index0wp6H3g3jBMeta } from "/root/project/frontend/apps/b2b/pages/leads/index.vue?macro=true";
import { default as indexVFvPfKLhMyMeta } from "/root/project/frontend/apps/b2b/pages/privacy-and-policy/index.vue?macro=true";
import { default as comparegn6zmMYmZEMeta } from "/root/project/frontend/apps/b2b/pages/profile/compare.vue?macro=true";
import { default as favoritesYs68NvaS7uMeta } from "/root/project/frontend/apps/b2b/pages/profile/favorites.vue?macro=true";
import { default as indextazRGr6qGdMeta } from "/root/project/frontend/apps/b2b/pages/profile/index.vue?macro=true";
import { default as notificationsL52RiYpq2hMeta } from "/root/project/frontend/apps/b2b/pages/profile/notifications.vue?macro=true";
import { default as profileYzU41TQbjBMeta } from "/root/project/frontend/apps/b2b/pages/profile.vue?macro=true";
import { default as _91category_93_45course9h3tZhkVJ4Meta } from "/root/project/frontend/apps/b2b/pages/search/[category]-course.vue?macro=true";
import { default as _91category_938p2EiWCJZvMeta } from "/root/project/frontend/apps/b2b/pages/search/[category].vue?macro=true";
import { default as indexmxUET6gL4mMeta } from "/root/project/frontend/apps/b2b/pages/team-members/index.vue?macro=true";
import { default as indexGsYupSqGrmMeta } from "/root/project/frontend/apps/b2b/pages/team-members/member/index.vue?macro=true";
import { default as indexgI0u81rJZsMeta } from "/root/project/frontend/apps/b2b/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexyu1sWwgY4PMeta } from "/root/project/frontend/apps/b2b/pages/thank-you/index.vue?macro=true";
import { default as _91id_93NY0gPdqqj5Meta } from "/root/project/frontend/apps/b2b/pages/university/[id].vue?macro=true";
import { default as _91id_93AiQARUQwdVMeta } from "/root/project/frontend/apps/b2b/pages/university/course/[id].vue?macro=true";
export default [
  {
    name: "application-transfer___en",
    path: "/application-transfer",
    component: () => import("/root/project/frontend/apps/b2b/pages/application-transfer.vue")
  },
  {
    name: "application-transfer___ar",
    path: "/ar/application-transfer",
    component: () => import("/root/project/frontend/apps/b2b/pages/application-transfer.vue")
  },
  {
    name: "applications___en",
    path: "/applications",
    component: () => import("/root/project/frontend/apps/b2b/pages/applications/index.vue")
  },
  {
    name: "applications___ar",
    path: "/ar/applications",
    component: () => import("/root/project/frontend/apps/b2b/pages/applications/index.vue")
  },
  {
    name: "apply___en",
    path: "/apply",
    component: () => import("/root/project/frontend/apps/b2b/pages/apply/index.vue")
  },
  {
    name: "apply___ar",
    path: "/ar/apply",
    component: () => import("/root/project/frontend/apps/b2b/pages/apply/index.vue")
  },
  {
    name: "apply-institute___en",
    path: "/apply/institute",
    component: () => import("/root/project/frontend/apps/b2b/pages/apply/institute.vue")
  },
  {
    name: "apply-institute___ar",
    path: "/ar/apply/institute",
    component: () => import("/root/project/frontend/apps/b2b/pages/apply/institute.vue")
  },
  {
    name: "apply-review___en",
    path: "/apply/review",
    component: () => import("/root/project/frontend/apps/b2b/pages/apply/review.vue")
  },
  {
    name: "apply-review___ar",
    path: "/ar/apply/review",
    component: () => import("/root/project/frontend/apps/b2b/pages/apply/review.vue")
  },
  {
    name: "auth-forgot-password___en",
    path: "/auth/forgot-password",
    meta: forgot_45passwordjodcNcqP8pMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-forgot-password___ar",
    path: "/ar/auth/forgot-password",
    meta: forgot_45passwordjodcNcqP8pMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login___en",
    path: "/auth/login",
    meta: loginDM1AA7Ce2cMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/auth/login.vue")
  },
  {
    name: "auth-login___ar",
    path: "/ar/auth/login",
    meta: loginDM1AA7Ce2cMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/auth/login.vue")
  },
  {
    name: "auth-new-password___en",
    path: "/auth/new-password",
    meta: new_45passwordnVan3PexgxMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/auth/new-password.vue")
  },
  {
    name: "auth-new-password___ar",
    path: "/ar/auth/new-password",
    meta: new_45passwordnVan3PexgxMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/auth/new-password.vue")
  },
  {
    name: "book-appointment___en",
    path: "/book-appointment",
    component: () => import("/root/project/frontend/apps/b2b/pages/book-appointment/index.vue")
  },
  {
    name: "book-appointment___ar",
    path: "/ar/book-appointment",
    component: () => import("/root/project/frontend/apps/b2b/pages/book-appointment/index.vue")
  },
  {
    name: "commission___en",
    path: "/commission",
    component: () => import("/root/project/frontend/apps/b2b/pages/commission/index.vue")
  },
  {
    name: "commission___ar",
    path: "/ar/commission",
    component: () => import("/root/project/frontend/apps/b2b/pages/commission/index.vue")
  },
  {
    name: "course-id___en",
    path: "/course/:id()",
    component: () => import("/root/project/frontend/apps/b2b/pages/course/[id]/index.vue")
  },
  {
    name: "course-id___ar",
    path: "/ar/course/:id()",
    component: () => import("/root/project/frontend/apps/b2b/pages/course/[id]/index.vue")
  },
  {
    name: "destinations___en",
    path: "/destinations",
    meta: indexdGfgqgVBQ8Meta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/destinations/index.vue")
  },
  {
    name: "destinations___ar",
    path: "/ar/destinations",
    meta: indexdGfgqgVBQ8Meta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/destinations/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/root/project/frontend/apps/b2b/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/root/project/frontend/apps/b2b/pages/index.vue")
  },
  {
    name: "institute-id___en",
    path: "/institute/:id()",
    meta: _91id_93R1P9V8MWvmMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/institute/[id].vue")
  },
  {
    name: "institute-id___ar",
    path: "/ar/institute/:id()",
    meta: _91id_93R1P9V8MWvmMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/institute/[id].vue")
  },
  {
    name: "institutes-id___en",
    path: "/institutes/:id()",
    component: () => import("/root/project/frontend/apps/b2b/pages/institutes/[id].vue")
  },
  {
    name: "institutes-id___ar",
    path: "/ar/institutes/:id()",
    component: () => import("/root/project/frontend/apps/b2b/pages/institutes/[id].vue")
  },
  {
    name: "institutes-course-id___en",
    path: "/institutes/course/:id()",
    meta: _91id_93eEn1OiYEoNMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/institutes/course/[id].vue")
  },
  {
    name: "institutes-course-id___ar",
    path: "/ar/institutes/course/:id()",
    meta: _91id_93eEn1OiYEoNMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/institutes/course/[id].vue")
  },
  {
    name: "leads-id___en",
    path: "/leads/:id()",
    component: () => import("/root/project/frontend/apps/b2b/pages/leads/[id].vue")
  },
  {
    name: "leads-id___ar",
    path: "/ar/leads/:id()",
    component: () => import("/root/project/frontend/apps/b2b/pages/leads/[id].vue")
  },
  {
    name: "leads___en",
    path: "/leads",
    component: () => import("/root/project/frontend/apps/b2b/pages/leads/index.vue")
  },
  {
    name: "leads___ar",
    path: "/ar/leads",
    component: () => import("/root/project/frontend/apps/b2b/pages/leads/index.vue")
  },
  {
    name: "privacy-and-policy___en",
    path: "/privacy-and-policy",
    component: () => import("/root/project/frontend/apps/b2b/pages/privacy-and-policy/index.vue")
  },
  {
    name: "privacy-and-policy___ar",
    path: "/ar/privacy-and-policy",
    component: () => import("/root/project/frontend/apps/b2b/pages/privacy-and-policy/index.vue")
  },
  {
    name: profileYzU41TQbjBMeta?.name,
    path: "/profile",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile.vue"),
    children: [
  {
    name: "profile-compare___en",
    path: "compare",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile/compare.vue")
  },
  {
    name: "profile-favorites___en",
    path: "favorites",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile/favorites.vue")
  },
  {
    name: "profile___en",
    path: "",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile/index.vue")
  },
  {
    name: "profile-notifications___en",
    path: "notifications",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile/notifications.vue")
  }
]
  },
  {
    name: profileYzU41TQbjBMeta?.name,
    path: "/ar/profile",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile.vue"),
    children: [
  {
    name: "profile-compare___ar",
    path: "compare",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile/compare.vue")
  },
  {
    name: "profile-favorites___ar",
    path: "favorites",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile/favorites.vue")
  },
  {
    name: "profile___ar",
    path: "",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile/index.vue")
  },
  {
    name: "profile-notifications___ar",
    path: "notifications",
    component: () => import("/root/project/frontend/apps/b2b/pages/profile/notifications.vue")
  }
]
  },
  {
    name: "search-category-course___en",
    path: "/search/:category()-course",
    meta: _91category_93_45course9h3tZhkVJ4Meta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/search/[category]-course.vue")
  },
  {
    name: "search-category-course___ar",
    path: "/ar/search/:category()-course",
    meta: _91category_93_45course9h3tZhkVJ4Meta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/search/[category]-course.vue")
  },
  {
    name: "search-category___en",
    path: "/search/:category()",
    meta: _91category_938p2EiWCJZvMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/search/[category].vue")
  },
  {
    name: "search-category___ar",
    path: "/ar/search/:category()",
    meta: _91category_938p2EiWCJZvMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/search/[category].vue")
  },
  {
    name: "team-members___en",
    path: "/team-members",
    component: () => import("/root/project/frontend/apps/b2b/pages/team-members/index.vue")
  },
  {
    name: "team-members___ar",
    path: "/ar/team-members",
    component: () => import("/root/project/frontend/apps/b2b/pages/team-members/index.vue")
  },
  {
    name: "team-members-member___en",
    path: "/team-members/member",
    component: () => import("/root/project/frontend/apps/b2b/pages/team-members/member/index.vue")
  },
  {
    name: "team-members-member___ar",
    path: "/ar/team-members/member",
    component: () => import("/root/project/frontend/apps/b2b/pages/team-members/member/index.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/root/project/frontend/apps/b2b/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___ar",
    path: "/ar/terms-and-conditions",
    component: () => import("/root/project/frontend/apps/b2b/pages/terms-and-conditions/index.vue")
  },
  {
    name: "thank-you___en",
    path: "/thank-you",
    component: () => import("/root/project/frontend/apps/b2b/pages/thank-you/index.vue")
  },
  {
    name: "thank-you___ar",
    path: "/ar/thank-you",
    component: () => import("/root/project/frontend/apps/b2b/pages/thank-you/index.vue")
  },
  {
    name: "university-id___en",
    path: "/university/:id()",
    meta: _91id_93NY0gPdqqj5Meta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/university/[id].vue")
  },
  {
    name: "university-id___ar",
    path: "/ar/university/:id()",
    meta: _91id_93NY0gPdqqj5Meta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/university/[id].vue")
  },
  {
    name: "university-course-id___en",
    path: "/university/course/:id()",
    meta: _91id_93AiQARUQwdVMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/university/course/[id].vue")
  },
  {
    name: "university-course-id___ar",
    path: "/ar/university/course/:id()",
    meta: _91id_93AiQARUQwdVMeta || {},
    component: () => import("/root/project/frontend/apps/b2b/pages/university/course/[id].vue")
  }
]