import api from '@/api';

export const state = () => ({
  loading: true,
  universitiesDetails: [],
  name: '',
  city: '',
  country: '',
  logo: '',
  ranking: '',
  description: '',
  image: '',
  map_link: '',
  university_courses_list: [[]],
  university_courses_list_total: null,
  university_courses_list_page: [[]],
  per_page_list: 6,
  searchWord: '',
  keyWordCourse: '',
  keyWordCourseQuery: '',
});

export const actions = {
  restData({ state }) {
    state.loading = true;
    state.universitiesDetails = [];
    state.name = '';
    state.city = '';
    state.country = '';
    state.logo = '';
    state.ranking = '';
    state.description = '';
    state.image = '';
    state.pathway = '';
    state.application_fees = '';
    state.university_courses_list = [[]];
  },
  async getUniversitiesDetails({ state }, payload) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/universities/${payload}`).then(res => {
      state.loading = false;
      state.universitiesDetails = res.data.data;
      state.name = state.universitiesDetails.name;
      state.country = state.universitiesDetails.country;
      state.city = state.universitiesDetails.city;
      state.logo = state.universitiesDetails.logo;
      state.ranking = state.universitiesDetails.ranking;
      state.description = state.universitiesDetails.description;
      state.image = state.universitiesDetails.image;
      state.map_link = state.universitiesDetails.map_link;
      state.pathway = state.universitiesDetails.pathway;
      state.application_fees = state.universitiesDetails.application_fees;
    });
  },
  async getUniversitiesDetailsCoursesList({ state }, payload) {
    const keyword = useCookie('keyword');
    state.keyWordCourse = keyword.value;
    if (state.keyWordCourse) {
      state.keyWordCourseQuery = `&course_name_keyword=${state.keyWordCourse}`;
    } else {
      state.keyWordCourseQuery = '';
    }
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(
        `https://${subDomain}.${useRuntimeConfig().public.baseURL}/university-courses?university_id[]=${
          payload.id
        }&per_page=${state.per_page_list}${state.searchWord}${state.keyWordCourseQuery}`
      )
      .then(res => {
        state.university_courses_list_total = res.data.meta.total;
        state.university_courses_list = [
          res.data.data.map(item => ({
            id: item.id,
            type: 'courses',
            name: item.name,
            offer: item.fees,
            offerCount: item.sale_percentage + '%',
            price: item.fees_after_sale,
            has_sale: item.has_sale,
            location: item.location,
            image: item.image,
            favourite: item.favourite,
            course_type: item.course_type,
            currency: item.currency,
          })),
        ];
      })
      .catch(() => {
        state.loading = false;
      });
  },
  async getUniversitiesDetailsCoursesListPage({ state }, payload) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(
        `https://${subDomain}.${useRuntimeConfig().public.baseURL}/university-courses?university_id[]=${
          payload.id
        }&per_page=${state.per_page_list}`
      )
      .then(res => {
        state.university_courses_list_page = [
          res.data.data.map(item => ({
            id: item.id,
            type: 'courses',
            name: item.name,
            offer: item.fees,
            offerCount: item.sale_percentage + '%',
            price: item.fees_after_sale,
            has_sale: item.has_sale,
            location: item.location,
            image: item.image,
            favourite: item.favourite,
            course_type: item.course_type,
            currency: item.currency,
          })),
        ];
      });
  },
};
